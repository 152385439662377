import React from 'react'
import FeaturedPost from '../components/cards/FeaturedPost';
import PopularList from '../components/lists/Popular';
import BlogPost from '../components/cards/BlogPost';
import Layout from '../components/layout/Layout';
import RichText from '../components/rich-text/RichText';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BOOK_URL } from '../data/constants';
import SEO from '../components/SEO';

const Post = ({ data: { post } }) => {
    const {
        title,
        description,
        content,
        published,
        coverImage,
        author,
        readingTime,
        similarArticles,
        popularList
    } = post
    return (
        <>
            <SEO
                title={title}
                image={`${coverImage.metaImage.src}`}
                description={description}
            />
            <Layout>
                <article className="min-h-screen" >
                    {/* SEO Component */}
                    <Img style={{ maxHeight: 400 }} fluid={coverImage.fluid} />
                    <div className="xl:grid xl:grid-cols-5 mx-auto max-w-screen-xl xl:max-w-screen-2xl xl:px-4"  >
                        <aside className="xl:block hidden pt-12 px-4  ">
                            <div className="sticky top-0 text-center space-y-4 pt-8">
                                <h2 className="text-2xl text-center">Find these tips helpful?</h2>
                                <a
                                    href={BOOK_URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-2xl py-2 px-4 block rounded bg-gradient-l-golden">Get the book</a>
                                <h3 className="text-xl text-center">And discover many more secrets</h3>
                            </div>
                        </aside>
                        <main className="md:-mt-32 relative z-10 bg-gray-100 col-span-3 max-w-screen-md xl:max-w-none mx-auto col-start-2 lg:px-16 md:px-8 px-4 pt-8 md:pt-16 pb-24">
                            <div className="w-full">
                                <span className="text-sm font-thin">{published}</span>
                                <h1 className="text-left text-4xl">{title} </h1>
                                <aside className="flex my-4">
                                    <Img className="rounded-full mr-6" fixed={author.photo.fixed} />
                                    <div>
                                        <h3 className=" mb-0 text-xl font-thin">{author.name}</h3>
                                        <h4 className="text-sm font-thin">{readingTime}</h4>
                                    </div>
                                </aside>
                                <section className="">
                                    <RichText className="leading-loose" text={content.json} />
                                </section>

                                {
                                    similarArticles &&
                                    <section className="max-w-screen-md mx-auto space-y-6">
                                        <h2 className="text-2xl my-8 text-center">Other articles you might enjoy</h2>
                                        {
                                            similarArticles && similarArticles.map(similarArticles =>
                                                <div className="">
                                                    <FeaturedPost featured {...similarArticles} className="" />
                                                </div>
                                            )
                                        }
                                    </section>
                                }
                            </div>
                            <aside className="xl:hidden pt-12 px-4  ">
                                <div className="sticky top-0 text-center space-y-4 pt-8">
                                    <h2 className="text-2xl text-center">Find these tips helpful?</h2>
                                    <a
                                        href={BOOK_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-2xl py-2 px-4 block rounded bg-gradient-l-golden max-w-xs mx-auto">Get the book</a>
                                    <h3 className="text-xl text-center">And discover many more secrets</h3>
                                </div>
                            </aside>

                        </main>
                        <aside className="xl:block hidden pt-12 px-4 sticky top-0 ">
                            <div className="sticky top-0 pt-8">
                                <h2 className="text-3xl text-center">Popular Posts</h2>
                                <div className="h-2 bg-gradient-l-golden  mx-auto rounded-full w-20"></div>

                                <PopularList posts={popularList} className=" mt-8 relative z-20 space-y-8 lg:space-y-8 mb-12 md:mb-0 row-span-2" />
                            </div>
                        </aside>
                    </div>

                </article>


            </Layout>
        </>
    )
}



export const BlogPostQuery = graphql`
  
  query BlogPostQuery($id: String) {
  post: contentfulPost(id: {eq: $id}) {
    title
    description
    coverImage {
      fluid(quality: 70, maxWidth: 1200) {
        ...GatsbyContentfulFluid_withWebp
      }
      metaImage: fixed(quality: 80, width: 350) {
        src
      }
      title
    }
    author {
      photo {
        fixed(width: 50, quality: 50) {
          ...GatsbyContentfulFixed_withWebp
        }
        title
      }
      name
    }
    content {
      json
    }
    readingTime
    published
    tags
    similarArticles {
      published
      readingTime
      slug
      title
      description
      featured
      coverImage {
        fluid(quality: 50, maxWidth: 250) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      author {
        photo {
          fixed(width: 50, quality: 50) {
            ...GatsbyContentfulFixed_withWebp
          }
          title
        }
        name
      }
    }
  }
}


`


export default Post